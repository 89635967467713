var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pb-10", attrs: { elevation: "0" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0 mb-6" },
        [
          _vm.showSignup
            ? _c(
                "v-row",
                {
                  staticClass: "mx-auto mt-2",
                  class: _vm.$vuetify.breakpoint.smAndDown ? "mb-8" : "mb-6",
                  attrs: { justify: "center" },
                },
                [
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mt-n3 ml-n0 my-auto",
                          attrs: {
                            fab: "",
                            text: "",
                            absolute: "",
                            plain: "",
                            left: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.$emit("updateStep", 1) && _vm.signUp()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass:
                        "text-h5 font-weight-bold grey--text text--darken-4 mx-auto text-center",
                      staticStyle: { display: "block" },
                    },
                    [
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? [_vm._v(" Company and Billing Information ")]
                        : [
                            _vm._v(" Company and Billing"),
                            _c("br"),
                            _vm._v("Information "),
                          ],
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.onSuccess
            ? _c(
                "v-col",
                {
                  staticClass: "pa-0 pr-2",
                  class: _vm.$vuetify.breakpoint.smAndDown
                    ? "pr-2 "
                    : "pr-6 pl-12 ml-60",
                  attrs: { cols: "12", lg: "5", md: "6", xl: "5" },
                },
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "px-1 ma-0 pr-2",
                      class: _vm.$vuetify.breakpoint.xsOnly
                        ? "text-caption"
                        : "text-subtitle-2",
                      attrs: {
                        "max-height": "250",
                        dense: "",
                        dismissible: "",
                        text: "",
                        icon: "mdi-check-circle-outline",
                        color: "green darken-4",
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("companyInformationUpdated")) + " "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.$vuetify.breakpoint.mdAndUp && _vm.showSignup
            ? _c("v-divider", { staticClass: "mx-8" })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-card-text",
        {
          staticClass: "pa-0",
          class: _vm.$vuetify.breakpoint.mdAndUp ? "px-6" : "pl-1 pr-0",
        },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "pl-3 company-info-form-container",
                  class: _vm.$vuetify.breakpoint.mdAndUp ? "pr-6" : "",
                  attrs: { cols: "12", lg: "6", md: "6" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-8 mb-6", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "subtitle-1 font-weight-medium grey--text text--darken-4",
                          attrs: { cols: "12" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("companyInformation")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.companyNameErrors,
                              label: _vm.$t("companyName"),
                              outlined: "",
                              required: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.enableCancelBtn("name")
                              },
                              blur: function ($event) {
                                return _vm.enableCancelBtn("name")
                              },
                            },
                            model: {
                              value: _vm.companyInfoData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyInfoData, "name", $$v)
                              },
                              expression: "companyInfoData.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.companyPhoneErrors,
                              label: _vm.$t("companyPhone"),
                              outlined: "",
                              required: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.enableCancelBtn("phone")
                              },
                              blur: function ($event) {
                                return _vm.enableCancelBtn("phone")
                              },
                            },
                            model: {
                              value: _vm.companyInfoData.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyInfoData, "phone", $$v)
                              },
                              expression: "companyInfoData.phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.companyEmailErrors,
                              disabled: !_vm.showSignup,
                              label: _vm.$t("companyEmail"),
                              outlined: "",
                              required: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.enableCancelBtn("email")
                              },
                              blur: function ($event) {
                                return _vm.enableCancelBtn("email")
                              },
                              keypress: function ($event) {
                                _vm.showErrorAlert = false
                              },
                            },
                            model: {
                              value: _vm.companyInfoData.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyInfoData, "email", $$v)
                              },
                              expression: "companyInfoData.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.companyAddressErrors,
                              label: _vm.$t("companyAddressText"),
                              outlined: "",
                              required: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.enableCancelBtn("address")
                              },
                              blur: function ($event) {
                                return _vm.enableCancelBtn("address")
                              },
                            },
                            model: {
                              value: _vm.companyInfoData.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyInfoData, "address", $$v)
                              },
                              expression: "companyInfoData.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.companyCityErrors,
                              label: _vm.$t("cityText"),
                              outlined: "",
                              required: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.enableCancelBtn("city")
                              },
                              blur: function ($event) {
                                return _vm.enableCancelBtn("city")
                              },
                            },
                            model: {
                              value: _vm.companyInfoData.city,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyInfoData, "city", $$v)
                              },
                              expression: "companyInfoData.city",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-2", attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.selectState,
                              "error-messages": _vm.companyStateErrors,
                              outlined: "",
                              "menu-props": { bottom: true, offsetY: true },
                              required: "",
                              dense: "",
                              label: _vm.$t("stateText"),
                              "item-value": "text",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.enableCancelBtn("state")
                              },
                              blur: function ($event) {
                                return _vm.enableCancelBtn("state")
                              },
                            },
                            model: {
                              value: _vm.companyInfoData.state,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyInfoData, "state", $$v)
                              },
                              expression: "companyInfoData.state",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.companyZipErrors,
                              label: _vm.$t("zipText"),
                              outlined: "",
                              required: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.enableCancelBtn("zip")
                              },
                              blur: function ($event) {
                                return _vm.enableCancelBtn("zip")
                              },
                            },
                            model: {
                              value: _vm.companyInfoData.zip,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyInfoData, "zip", $$v)
                              },
                              expression: "companyInfoData.zip",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.selectedCompanySubscription.name !== "Shovel Ready" &&
                  _vm.showbillingData
                    ? _c("v-divider")
                    : _vm._e(),
                  _vm.selectedCompanySubscription.name !== "Shovel Ready"
                    ? _c(
                        "div",
                        [
                          _vm.showbillingData
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "mt-6",
                                  attrs: {
                                    "no-gutters": "",
                                    justify: "center",
                                  },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "subtitle-1 font-weight-medium grey--text text--darken-4",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("billingInformation")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showbillingData
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    "no-gutters": "",
                                    justify: "center",
                                  },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          disabled: _vm.disableCheckBox,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.setBillingAddress()
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "subtitle-2 font-weight-regular grey--text text--darken-4",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "sameBillingAndCompanyAddress"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          660204115
                                        ),
                                        model: {
                                          value: _vm.isBillAddressSame,
                                          callback: function ($$v) {
                                            _vm.isBillAddressSame = $$v
                                          },
                                          expression: "isBillAddressSame",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isCheckedState
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass:
                                            "billing-address-card rounded-lg mb-4",
                                          attrs: {
                                            elevation: "0",
                                            color: "#F5F5F5",
                                            outlined: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card-subtitle",
                                            {
                                              staticClass:
                                                "body-2 font-weight-regular grey--text text--darken-4",
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.companyInfoData.name
                                                  )
                                                ),
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.companyInfoData
                                                        .address
                                                    ) +
                                                    ", " +
                                                    _vm._s(
                                                      _vm.companyInfoData.city
                                                    ) +
                                                    ", " +
                                                    _vm._s(
                                                      _vm.companyInfoData.state
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.companyInfoData.zip
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  "Phone: " +
                                                    _vm._s(
                                                      _vm.companyInfoData.phone
                                                    )
                                                ),
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  "Email: " +
                                                    _vm._s(
                                                      _vm.companyInfoData.email
                                                    )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _vm.showbillingData
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { justify: "center" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: {
                                                    cols: "12",
                                                    lg: "12",
                                                    md: "12",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "error-messages":
                                                        _vm.billingNameErrors,
                                                      label:
                                                        _vm.$t(
                                                          "billingContact"
                                                        ),
                                                      outlined: "",
                                                      dense: "",
                                                      required: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.enableCancelBtn(
                                                          "billing_contact"
                                                        )
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.enableCancelBtn(
                                                          "billing_contact"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.companyInfoData
                                                          .billing_contact,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.companyInfoData,
                                                          "billing_contact",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "companyInfoData.billing_contact",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { justify: "center" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "error-messages":
                                                        _vm.billingPhoneErrors,
                                                      label: _vm.$t(
                                                        "billing_contact_phone"
                                                      ),
                                                      outlined: "",
                                                      dense: "",
                                                      required: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.enableCancelBtn(
                                                          "billing_phone"
                                                        )
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.enableCancelBtn(
                                                          "billing_phone"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.companyInfoData
                                                          .billing_phone,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.companyInfoData,
                                                          "billing_phone",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "companyInfoData.billing_phone",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "error-messages":
                                                        _vm.billingEmailErrors,
                                                      label:
                                                        _vm.$t("contact_email"),
                                                      outlined: "",
                                                      dense: "",
                                                      required: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.enableCancelBtn(
                                                          "billing_email"
                                                        )
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.enableCancelBtn(
                                                          "billing_email"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.companyInfoData
                                                          .billing_email,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.companyInfoData,
                                                          "billing_email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "companyInfoData.billing_email",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { justify: "center" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "error-messages":
                                                        _vm.billingAddressErrors,
                                                      label:
                                                        _vm.$t(
                                                          "companyAddressText"
                                                        ),
                                                      outlined: "",
                                                      dense: "",
                                                      required: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.enableCancelBtn(
                                                          "billing_address"
                                                        )
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.enableCancelBtn(
                                                          "billing_address"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.companyInfoData
                                                          .billing_address,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.companyInfoData,
                                                          "billing_address",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "companyInfoData.billing_address",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "error-messages":
                                                        _vm.billingCityErrors,
                                                      label: _vm.$t("cityText"),
                                                      outlined: "",
                                                      dense: "",
                                                      required: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.enableCancelBtn(
                                                          "billing_city"
                                                        )
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.enableCancelBtn(
                                                          "billing_city"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.companyInfoData
                                                          .billing_city,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.companyInfoData,
                                                          "billing_city",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "companyInfoData.billing_city",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { justify: "center" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.selectState,
                                                      "error-messages":
                                                        _vm.billingStateErrors,
                                                      "menu-props": {
                                                        bottom: true,
                                                        offsetY: true,
                                                      },
                                                      label:
                                                        _vm.$t("stateText"),
                                                      outlined: "",
                                                      dense: "",
                                                      required: "",
                                                      "item-value": "text",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.enableCancelBtn(
                                                          "billing_state"
                                                        )
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.enableCancelBtn(
                                                          "billing_state"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.companyInfoData
                                                          .billing_state,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.companyInfoData,
                                                          "billing_state",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "companyInfoData.billing_state",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "error-messages":
                                                        _vm.billingZipErrors,
                                                      label: _vm.$t("zipText"),
                                                      outlined: "",
                                                      dense: "",
                                                      required: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.enableCancelBtn(
                                                          "billing_zip"
                                                        )
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.enableCancelBtn(
                                                          "billing_zip"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.companyInfoData
                                                          .billing_zip,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.companyInfoData,
                                                          "billing_zip",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "companyInfoData.billing_zip",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                          !_vm.showSignup && _vm.showMFA
                            ? _c(
                                "v-row",
                                { staticClass: "py-4 pt-6" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-4 text-subtitle-2 mr-4",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("multiFactorAuthentication")
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      staticClass: "pa-0",
                                      attrs: {
                                        right:
                                          !_vm.$vuetify.breakpoint.smAndDown,
                                        bottom:
                                          _vm.$vuetify.breakpoint.smAndDown,
                                        "max-width": "400",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          dark: "",
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-information-outline "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2517027790
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "multiFactorAuthenticationTooltip"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.showSignup && _vm.showMFA
                            ? _c("v-row", [_c("v-divider")], 1)
                            : _vm._e(),
                          !_vm.showSignup && _vm.showMFA
                            ? _c(
                                "v-row",
                                [
                                  _c("v-switch", {
                                    staticClass:
                                      "info--text switch-track-inset-opacity switch-track-inset-height",
                                    attrs: {
                                      disabled:
                                        !_vm.checkPermission("multiFactorAuth"),
                                      inset: "",
                                      color: "info",
                                      label: "Multi-factor authentication",
                                      readonly: _vm.MFACheck,
                                      "background-color": "white",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onMFAChange()
                                      },
                                    },
                                    model: {
                                      value: _vm.MFACheck,
                                      callback: function ($$v) {
                                        _vm.MFACheck = $$v
                                      },
                                      expression: "MFACheck",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showbillingData
                    ? _c(
                        "v-row",
                        {
                          class: _vm.$vuetify.breakpoint.mdAndUp ? "" : "pl-6",
                          attrs: { justify: "start", "no-gutters": "" },
                        },
                        [
                          _c("v-checkbox", {
                            attrs: { label: _vm.$t("iAgree") },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "mr-2 grey--text text--darken-4 text-subtitle-2 font-weight-regular",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("iAgree")) + " "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "primary--text mt-n1",
                                              on: {
                                                click: function ($event) {
                                                  _vm.agreementDialog = true
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("termsAndConditions")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1664808064
                            ),
                            model: {
                              value: _vm.checkbox,
                              callback: function ($$v) {
                                _vm.checkbox = $$v
                              },
                              expression: "checkbox",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showSignup
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-none rounded-lg",
                                  attrs: {
                                    block: "",
                                    color: "primary",
                                    disabled: _vm.$v.$invalid || !_vm.checkbox,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submit()
                                    },
                                  },
                                },
                                [_vm._v(" Submit ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { "col-6": "" } },
                            [
                              _vm.checkPermission("editCompany") ||
                              _vm.companyInformation
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-none rounded-lg",
                                      attrs: {
                                        block: "",
                                        color: "primary",
                                        disabled:
                                          _vm.$v.$invalid ||
                                          !_vm.checkbox ||
                                          _vm.disableSaveBtn,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateCompany()
                                        },
                                      },
                                    },
                                    [_vm._v(" Save Changes ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { "col-6": "" } },
                            [
                              _vm.checkPermission("editCompany") ||
                              _vm.companyInformation
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-none rounded-lg",
                                      attrs: {
                                        block: "",
                                        color: "primary",
                                        disabled:
                                          _vm.isFormSubmitting ||
                                          !_vm.isCancelBtnEnabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancelUpdate()
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("MFADialog", {
        attrs: { dialog: _vm.dialog },
        on: { disableMFA: _vm.disableMFA, closeDialog: _vm.closeDialog },
      }),
      _c("CompanyAgreement", {
        attrs: { dialog: _vm.agreementDialog },
        on: {
          closeDialog: _vm.closeAgreementDialog,
          agree: _vm.acceptAgreement,
          declineAgreement: _vm.declineAgreement,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }