<template>
  <v-card elevation="0" class="pb-10">
    <v-card-text class="pa-0 mb-6">
      <v-row
        v-if="showSignup"
        justify="center"
        class="mx-auto mt-2"
        :class="$vuetify.breakpoint.smAndDown ? 'mb-8' : 'mb-6'"
      >
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          fab
          text
          absolute
          plain
          left
          class="mt-n3 ml-n0 my-auto"
          @click="$emit('updateStep', 1) && signUp()"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <span
          class="text-h5 font-weight-bold grey--text text--darken-4 mx-auto text-center"
          style="display: block"
        >
          <template v-if="$vuetify.breakpoint.mdAndUp">
            Company and Billing Information
          </template>
          <template v-else>
            Company and Billing<br>Information
          </template>
        </span>
      </v-row>
      <v-col
        v-if="onSuccess"
        cols="12"
        lg="5"
        md="6"
        xl="5"
        class="pa-0 pr-2"
        :class="$vuetify.breakpoint.smAndDown ? 'pr-2 ' : 'pr-6 pl-12 ml-60'"
      >
        <v-alert
          max-height="250"
          dense
          dismissible
          text
          :class="
            $vuetify.breakpoint.xsOnly ? 'text-caption' : 'text-subtitle-2'
          "
          class="px-1 ma-0 pr-2"
          icon="mdi-check-circle-outline"
          color="green darken-4"
        >
          {{ $t("companyInformationUpdated") }}
        </v-alert>
      </v-col>
      <v-divider
        v-if="$vuetify.breakpoint.mdAndUp && showSignup"
        class="mx-8"
      ></v-divider>
    </v-card-text>

    <v-card-text
      class="pa-0"
      :class="$vuetify.breakpoint.mdAndUp ? 'px-6' : 'pl-1 pr-0'"
    >
      <v-row no-gutters justify="center">
        <v-col
          cols="12"
          lg="6"
          md="6"
          class="pl-3 company-info-form-container"
          :class="$vuetify.breakpoint.mdAndUp ? 'pr-6' : ''"
        >
          <v-row no-gutters class="mt-8 mb-6">
            <v-col
              cols="12"
              class="subtitle-1 font-weight-medium grey--text text--darken-4"
            >
              {{ $t("companyInformation") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="companyInfoData.name"
                :error-messages="companyNameErrors"
                :label="$t('companyName')"
                outlined
                required
                dense
                @input="enableCancelBtn('name')"
                @blur="enableCancelBtn('name')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="companyInfoData.phone"
                :error-messages="companyPhoneErrors"
                :label="$t('companyPhone')"
                outlined
                required
                dense
                @input="enableCancelBtn('phone')"
                @blur="enableCancelBtn('phone')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="companyInfoData.email"
                :error-messages="companyEmailErrors"
                :disabled="!showSignup"
                :label="$t('companyEmail')"
                outlined
                required
                dense
                @input="enableCancelBtn('email')"
                @blur="enableCancelBtn('email')"
                @keypress="showErrorAlert = false"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="companyInfoData.address"
                :error-messages="companyAddressErrors"
                :label="$t('companyAddressText')"
                outlined
                required
                dense
                @input="enableCancelBtn('address')"
                @blur="enableCancelBtn('address')"
              ></v-text-field>
            </v-col> </v-row
          ><v-row>
            <v-col class="py-0">
              <v-text-field
                v-model="companyInfoData.city"
                :error-messages="companyCityErrors"
                :label="$t('cityText')"
                outlined
                required
                dense
                @input="enableCancelBtn('city')"
                @blur="enableCancelBtn('city')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" class="mb-2">
            <v-col cols="12" class="py-0">
              <v-select
                v-model="companyInfoData.state"
                :items="selectState"
                :error-messages="companyStateErrors"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                required
                dense
                :label="$t('stateText')"
                item-value="text"
                @change="enableCancelBtn('state')"
                @blur="enableCancelBtn('state')"
              ></v-select>
            </v-col> </v-row
          ><v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="companyInfoData.zip"
                :error-messages="companyZipErrors"
                :label="$t('zipText')"
                outlined
                required
                dense
                @input="enableCancelBtn('zip')"
                @blur="enableCancelBtn('zip')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider
            v-if="
              selectedCompanySubscription.name !== 'Shovel Ready' &&
              showbillingData
            "
          />
          <div v-if="selectedCompanySubscription.name !== 'Shovel Ready'">
            <v-row
              v-if="showbillingData"
              no-gutters
              justify="center"
              class="mt-6"
            >
              <v-col
                cols="12"
                class="subtitle-1 font-weight-medium grey--text text--darken-4"
              >
                {{ $t("billingInformation") }}
              </v-col>
            </v-row>
            <v-row
              v-if="showbillingData"
              no-gutters
              justify="center"
              class="mb-4"
            >
              <v-col cols="12">
                <v-checkbox
                  v-model="isBillAddressSame"
                  :disabled="disableCheckBox"
                  @change="setBillingAddress()"
                >
                  <template v-slot:label>
                    <div
                      class="subtitle-2 font-weight-regular grey--text text--darken-4"
                    >
                      {{ $t("sameBillingAndCompanyAddress") }}
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="isCheckedState">
              <v-col cols="12" class="py-0">
                <v-card
                  elevation="0"
                  color="#F5F5F5"
                  outlined
                  class="billing-address-card rounded-lg mb-4"
                >
                  <v-card-subtitle
                    class="body-2 font-weight-regular grey--text text--darken-4"
                  >
                    <div>{{ companyInfoData.name }}</div>
                    <div>
                      {{ companyInfoData.address }}, {{ companyInfoData.city }},
                      {{ companyInfoData.state }}
                      {{ companyInfoData.zip }}
                    </div>
                    <div>Phone: {{ companyInfoData.phone }}</div>
                    <div>Email: {{ companyInfoData.email }}</div>
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-else no-gutters>
              <v-col cols="12" v-if="showbillingData">
                <v-row justify="center">
                  <v-col cols="12" lg="12" md="12" class="py-0">
                    <v-text-field
                      v-model="companyInfoData.billing_contact"
                      :error-messages="billingNameErrors"
                      :label="$t('billingContact')"
                      outlined
                      dense
                      required
                      @input="enableCancelBtn('billing_contact')"
                      @blur="enableCancelBtn('billing_contact')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="companyInfoData.billing_phone"
                      :error-messages="billingPhoneErrors"
                      :label="$t('billing_contact_phone')"
                      outlined
                      dense
                      required
                      @input="enableCancelBtn('billing_phone')"
                      @blur="enableCancelBtn('billing_phone')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="companyInfoData.billing_email"
                      :error-messages="billingEmailErrors"
                      :label="$t('contact_email')"
                      outlined
                      dense
                      required
                      @input="enableCancelBtn('billing_email')"
                      @blur="enableCancelBtn('billing_email')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="companyInfoData.billing_address"
                      :error-messages="billingAddressErrors"
                      :label="$t('companyAddressText')"
                      outlined
                      dense
                      required
                      @input="enableCancelBtn('billing_address')"
                      @blur="enableCancelBtn('billing_address')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="companyInfoData.billing_city"
                      :error-messages="billingCityErrors"
                      :label="$t('cityText')"
                      outlined
                      dense
                      required
                      @input="enableCancelBtn('billing_city')"
                      @blur="enableCancelBtn('billing_city')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12" class="py-0">
                    <v-select
                      v-model="companyInfoData.billing_state"
                      :items="selectState"
                      :error-messages="billingStateErrors"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :label="$t('stateText')"
                      outlined
                      dense
                      required
                      item-value="text"
                      @change="enableCancelBtn('billing_state')"
                      @blur="enableCancelBtn('billing_state')"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="companyInfoData.billing_zip"
                      :error-messages="billingZipErrors"
                      :label="$t('zipText')"
                      outlined
                      dense
                      required
                      @input="enableCancelBtn('billing_zip')"
                      @blur="enableCancelBtn('billing_zip')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="py-4 pt-6" v-if="!showSignup && showMFA">
              <span class="grey--text text--darken-4 text-subtitle-2 mr-4"
                >{{ $t("multiFactorAuthentication") }} </span
              ><v-tooltip
                :right="!$vuetify.breakpoint.smAndDown"
                :bottom="$vuetify.breakpoint.smAndDown"
                max-width="400"
                class="pa-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t("multiFactorAuthenticationTooltip") }}</span>
              </v-tooltip>
            </v-row>
            <v-row v-if="!showSignup && showMFA">
              <v-divider></v-divider>
            </v-row>
            <v-row v-if="!showSignup && showMFA">
              <v-switch
                v-model="MFACheck"
                :disabled="!checkPermission('multiFactorAuth')"
                inset
                color="info"
                label="Multi-factor authentication"
                class="info--text switch-track-inset-opacity switch-track-inset-height"
                :readonly="MFACheck"
                background-color="white"
                @click="onMFAChange()"
              ></v-switch>
            </v-row>
          </div>
          <v-row
            v-if="showbillingData"
            justify="start"
            :class="$vuetify.breakpoint.mdAndUp ? '' : 'pl-6'"
            no-gutters
          >
            <v-checkbox v-model="checkbox" :label="$t('iAgree')"
              ><template v-slot:label>
                <span
                  class="mr-2 grey--text text--darken-4 text-subtitle-2 font-weight-regular"
                  >{{ $t("iAgree") }}
                  <a
                    class="primary--text mt-n1"
                    @click="agreementDialog = true"
                    >{{ $t("termsAndConditions") }}</a
                  ></span
                >
              </template></v-checkbox
            >
          </v-row>
          <v-row v-if="showSignup">
            <v-col cols="12" class="py-0">
              <v-btn
                block
                color="primary"
                class="text-none rounded-lg"
                :disabled="$v.$invalid || !checkbox"
                @click="submit()"
              >
                Submit
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col col-6>
              <v-btn
                v-if="checkPermission('editCompany') || companyInformation"
                block
                color="primary"
                class="text-none rounded-lg"
                :disabled="$v.$invalid || !checkbox || disableSaveBtn"
                @click="updateCompany()"
              >
                Save Changes
              </v-btn>
            </v-col>
            <v-col col-6>
              <v-btn
                v-if="checkPermission('editCompany') || companyInformation"
                block
                color="primary"
                class="text-none rounded-lg"
                :disabled="isFormSubmitting || !isCancelBtnEnabled"
                @click="cancelUpdate()"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <MFADialog
      :dialog="dialog"
      @disableMFA="disableMFA"
      @closeDialog="closeDialog"
    />

    <CompanyAgreement
      :dialog="agreementDialog"
      @closeDialog="closeAgreementDialog"
      @agree="acceptAgreement"
      @declineAgreement="declineAgreement"
    />
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { helpers } from "vuelidate/lib/validators";
import CompanyAgreement from "../../components/workflow/accountSetup/CompanyAgreement.vue";
import {
  required,
  maxLength,
  minLength,
  email,
} from "vuelidate/lib/validators";
import states from "@/static/states.json";
const zip = helpers.regex("serial", /^\d{5}(-\d{4})?$/);
const phone = helpers.regex(
  "serial",
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
);
import UserData from "../../mixins/UserData.vue";
import MFADialog from "../workflow/company/MFADialog.vue";
export default {
  name: "CompanyInformation",
  components: {
    CompanyAgreement,
    MFADialog,
  },

  mixins: [validationMixin, UserData],
  props: {
    subscriptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectedSubscription: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showSignup: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    showPrepopulatedData: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    companyInformation: {
      type: Object,
      default: () => {},
    },
  },
  validations() {
    if (this.selectedCompanySubscription.name !== "Shovel Ready") {
      return {
        companyInfoData: {
          name: {
            required,
            maxLength: maxLength(80),
            minLength: minLength(1),
          },
          address: {
            required,
            maxLength: maxLength(80),
            minLength: minLength(1),
          },
          city: {
            required,
            maxLength: maxLength(80),
            minLength: minLength(1),
          },
          state: { required },
          zip: { required, zip },
          phone: { required, phone },
          email: { required, email },
          billing_contact: {
            required,
            maxLength: maxLength(80),
            minLength: minLength(1),
          },
          billing_address: {
            required,
            maxLength: maxLength(80),
            minLength: minLength(1),
          },
          billing_city: {
            required,
            maxLength: maxLength(80),
            minLength: minLength(1),
          },
          billing_state: { required },
          billing_zip: { required, zip },
          billing_phone: { required, phone },
          billing_email: { required, email },
        },
      };
    } else {
      return {
        companyInfoData: {
          name: {
            required,
            maxLength: maxLength(80),
            minLength: minLength(1),
          },
          address: {
            required,
            maxLength: maxLength(80),
            minLength: minLength(1),
          },
          city: {
            required,
            maxLength: maxLength(80),
            minLength: minLength(1),
          },
          state: { required },
          zip: { required, zip },
          phone: { required, phone },
          email: { required, email },
        },
      };
    }
  },
  data() {
    return {
      dialog: false,
      showbillingData: true,
      selectedCompanySubscription: {},
      step: 1,
      agreementDialog: false,
      selectState: states.state,
      isBillAddressSame: false,
      disableSaveBtn: false,
      companyInfoData: {
        name: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        email: "",
        billing_contact: "",
        billing_address: "",
        billing_city: "",
        billing_state: "",
        billing_zip: "",
        billing_phone: "",
        billing_email: "",
      },
      companyPreviousData: {},
      MFACheck: false,
      checkbox: false,
      onSuccess: false,
      isFormSubmitting: false,
      isCancelBtnEnabled: false,
      showMFA: false,
      showErrorAlert: false,
    };
  },

  computed: {
    companyNameErrors() {
      const errors = [];

      if (!this.$v.companyInfoData.name.$dirty) {
        return errors;
      }
      !this.$v.companyInfoData.name.maxLength &&
        errors.push(this.$t("companyNameLengthError"));
      !this.$v.companyInfoData.name.required &&
        errors.push(this.$t("companyNameRequiredError"));
      return errors;
    },
    companyAddressErrors() {
      const errors = [];
      if (!this.$v.companyInfoData.address.$dirty) return errors;
      !this.$v.companyInfoData.address.maxLength &&
        errors.push(this.$t("companyAddressLengthError"));
      !this.$v.companyInfoData.address.required &&
        errors.push(this.$t("companyAddressRequiredError"));
      return errors;
    },
    companyPhoneErrors() {
      const errors = [];
      if (!this.$v.companyInfoData.phone.$dirty) return errors;
      !this.$v.companyInfoData.phone.phone &&
        errors.push(this.$t("companyPhoneValidError"));
      !this.$v.companyInfoData.phone.required &&
        errors.push(this.$t("phoneRequiredError"));
      return errors;
    },
    companyEmailErrors() {
      const errors = [];
      if (!this.$v.companyInfoData.email.$dirty) return errors;
      !this.$v.companyInfoData.email.email &&
        errors.push(this.$t("companyEmailValidError"));
      !this.$v.companyInfoData.email.required &&
        errors.push(this.$t("emailRequiredError"));
      this.showErrorAlert && errors.push(this.$t("emailAllreadyRegistered"));
      return errors;
    },
    companyCityErrors() {
      const errors = [];
      if (!this.$v.companyInfoData.city.$dirty) return errors;
      !this.$v.companyInfoData.city.maxLength &&
        errors.push(this.$t("cityLengthError"));
      !this.$v.companyInfoData.city.required &&
        errors.push(this.$t("cityRequiredError"));
      return errors;
    },
    companyStateErrors() {
      const errors = [];
      if (!this.$v.companyInfoData.state.$dirty) return errors;
      !this.$v.companyInfoData.state.required &&
        errors.push(this.$t("stateRequiredError"));
      return errors;
    },
    companyZipErrors() {
      const errors = [];
      if (!this.$v.companyInfoData.zip.$dirty) return errors;
      !this.$v.companyInfoData.zip.zip && errors.push(this.$t("zipValidError"));
      !this.$v.companyInfoData.zip.required &&
        errors.push(this.$t("zipRequiredError"));
      return errors;
    },
    billingNameErrors() {
      const errors = [];
      if (!this.$v.companyInfoData.billing_contact.$dirty) return errors;
      !this.$v.companyInfoData.billing_contact.maxLength &&
        errors.push(this.$t("billingNameLengthError"));
      !this.$v.companyInfoData.billing_contact.required &&
        errors.push(this.$t("billingNameRequiredError"));
      return errors;
    },
    billingAddressErrors() {
      const errors = [];
      if (!this.$v.companyInfoData.billing_address.$dirty) return errors;
      !this.$v.companyInfoData.billing_address.maxLength &&
        errors.push(this.$t("billingAddressLengthError"));
      !this.$v.companyInfoData.billing_address.required &&
        errors.push(this.$t("billingAddressRequiredError"));
      return errors;
    },
    billingPhoneErrors() {
      const errors = [];
      if (!this.$v.companyInfoData.billing_phone.$dirty) return errors;
      !this.$v.companyInfoData.billing_phone.phone &&
        errors.push(this.$t("billingPhoneValidError"));
      !this.$v.companyInfoData.billing_phone.required &&
        errors.push(this.$t("phoneRequiredError"));
      return errors;
    },
    billingEmailErrors() {
      const errors = [];
      if (!this.$v.companyInfoData.billing_email.$dirty) return errors;
      !this.$v.companyInfoData.billing_email.email &&
        errors.push(this.$t("billingEmailValidError"));
      !this.$v.companyInfoData.billing_email.required &&
        errors.push(this.$t("emailRequiredError"));
      return errors;
    },
    billingCityErrors() {
      const errors = [];
      if (!this.$v.companyInfoData.billing_city.$dirty) return errors;
      !this.$v.companyInfoData.billing_city.maxLength &&
        errors.push(this.$t("cityLengthError"));
      !this.$v.companyInfoData.billing_city.required &&
        errors.push(this.$t("cityRequiredError"));
      return errors;
    },
    billingStateErrors() {
      const errors = [];
      if (!this.$v.companyInfoData.billing_state.$dirty) return errors;
      !this.$v.companyInfoData.billing_state.required &&
        errors.push(this.$t("stateRequiredError"));
      return errors;
    },
    billingZipErrors() {
      const errors = [];
      if (!this.$v.companyInfoData.billing_zip.$dirty) return errors;
      !this.$v.companyInfoData.billing_zip.zip &&
        errors.push(this.$t("zipValidError"));
      !this.$v.companyInfoData.billing_zip.required &&
        errors.push(this.$t("zipRequiredError"));
      return errors;
    },
    disableCheckBox() {
      return (
        this.$v.companyInfoData.name.$invalid ||
        this.$v.companyInfoData.phone.$invalid ||
        this.$v.companyInfoData.email.$invalid ||
        this.$v.companyInfoData.zip.$invalid ||
        this.$v.companyInfoData.city.$invalid ||
        this.$v.companyInfoData.address.$invalid ||
        this.$v.companyInfoData.state.$invalid
      );
    },
    showSignupData() {
      if (!this.$store.state.Company.fromUserSetting) return true;
      else return false;
    },
    companyInfo() {
      this.setMFA(this.$store.state.Company.company);
      return this.$store.state.Company.company;
    },
    isCheckedState() {
      return this.isBillAddressSame;
    },
  },

  async created() {
    this.selectedCompanySubscription = this.selectedSubscription;
    if (this.showSignup && this.showPrepopulatedData) {
      await this.handleContactData();
    } else if (
      !this.showSignup &&
      !this.showPrepopulatedData &&
      !this.companyInformation
    ) {
      this.showMFA = true;
      this.$watch(
        () => this.$store.state.User.user.company_id,
        (newId, oldId) => {
          if (newId && newId !== oldId) {
            this.handleCompanyData();
          }
        }
      );
      await this.handleCompanyData();
    } else if (
      !this.showSignup &&
      Object.keys(this.companyInformation).length !== 0
    ) {
      this.showMFA = false;

      await this.getCompanyAdmins();
    } else this.companyInfoData = {};
  },
  watch: {
    onSuccess() {
      if (this.onSuccess === true) {
        setTimeout(() => {
          this.onSuccess = false;
        }, 3000);
      }
    },
  },
  methods: {
    async fetchCompanyData() {
      const CompanyData = await this.$store.dispatch("getCompanyDataById", {
        companyId: this.companyInformation.company_id,
      });
      if (CompanyData.status !== "error") {
        this.companyPreviousData = Object.assign({}, CompanyData[0]);
        this.companyPreviousData.company_id = CompanyData[0].company_id;
        this.companyInfoData = Object.assign({}, CompanyData[0]);
        this.companyInfoData.isBillAddressSame =
          CompanyData[0].isBillAddressSame;
      } else {
        console.log("error");
      }
    },
    async getCompanyAdmins() {
      const companyUsers = await this.$store.dispatch("getCompanyAdmins", {
        company_id: this.companyInformation.company_id,
      });
      if (companyUsers.status !== "error") {
        this.users = companyUsers;
      }
      await this.fetchCompanyData();
    },
    enableCancelBtn(fieldName) {
      this.$v.companyInfoData[fieldName].$touch();
      if (this.isFormSubmitting) {
        this.isFormSubmitting = false;
      }
      this.isCancelBtnEnabled = true;
      this.disableSaveBtn = false;
    },
    cancelUpdate() {
      this.isBillAddressSame = false;
      this.companyInfoData = { ...this.companyPreviousData };
      this.isCancelBtnEnabled = false;
    },
    signUp() {
      if (this.$route.path !== "/signup") {
        this.$router.push("/signup").catch();
        this.$emit("update:showPrepopulatedData", false);
      }
    },
    handleSystemAdminData() {
      this.companyPreviousData = Object.assign({}, this.companyInformation);
      this.companyPreviousData.company_id = this.companyInformation.company_id;
      this.companyInfoData = Object.assign({}, this.companyInformation);
      this.companyInfoData.isBillAddressSame =
        this.companyInformation.isBillAddressSame;
    },

    async handleContactData() {
      const contactData = this.$store.state.Subscription.contactusData;
      if (Object.keys(contactData).length > 0) {
        this.companyInfoData.name = contactData.companyName;
        this.companyInfoData.phone = contactData.phoneNumber;
        this.companyInfoData.email = contactData.email;
        this.isBillAddressSame = false;
      }
    },

    async handleCompanyData() {
      const user = await this.$store.state.User.user;
      const id = user.company_id;
      if (user.persona === "sysop") {
        this.showbillingData = false;
      }
      if (id) {
        const data = await this.$store.dispatch("getCompanyDataById", {
          companyId: id,
        });
        const companyData = data[0];
        this.companyPreviousData = Object.assign({}, companyData);
        this.companyPreviousData.company_id = companyData.company_id;
        this.companyInfoData = Object.assign({}, companyData);
        this.companyInfoData.isBillAddressSame = companyData.isBillAddressSame;
        this.MFACheck = companyData.options?.settings.mfa;
      }
    },
    async submitMFA() {
      const MFAobject = {};
      MFAobject.options = this.companyInfoData.options;
      MFAobject.company_id = await this.companyInfoData.company_id;
      const CompanyStatus = await this.$store.dispatch(
        "updateCompany",
        MFAobject
      );
      if (CompanyStatus.status !== "error") {
        this.onSuccess = true;
      }
    },
    closeAgreementDialog() {
      this.agreementDialog = false;
    },
    closeDialog() {
      this.dialog = false;
    },
    disableMFA() {
      if (!this.companyInfoData.options) this.companyInfoData.options = {};
      if (!this.companyInfoData.options["settings"])
        this.companyInfoData.options["settings"] = {};
      this.companyInfoData.options["settings"]["mfa"] = false;
      this.MFACheck = false;
      this.dialog = false;
      this.submitMFA();
    },
    setMFA() {
      this.companyPreviousData = Object.assign(
        {},
        this.$store.state.Company.company
      );

      if (
        this.companyInfoData.options &&
        this.companyInfoData.options.settings &&
        this.companyInfoData.options.settings.mfa
      ) {
        this.MFACheck = this.companyInfoData.options.settings.mfa;
      } else {
        this.MFACheck = false;
      }
    },
    onMFAChange() {
      if (
        this.companyInfoData.options &&
        this.companyInfoData.options.settings &&
        this.companyInfoData.options.settings.mfa
      ) {
        this.dialog = true;
      } else {
        if (!this.companyInfoData.options)
          this.companyInfoData.options = { settings: { mfa: true } };
        if (!this.companyInfoData.options["settings"])
          this.companyInfoData.options["settings"] = { mfa: true };
        if (!this.companyInfoData.options.settings.mfa)
          this.companyInfoData.options.settings.mfa = true;
        this.submitMFA();
      }
    },

    getChanges(previous, current) {
      const typeOf = (obj) => Object.prototype.toString.call(obj);
      const isObject = (obj) =>
        obj !== null &&
        !Array.isArray(obj) &&
        typeOf(obj).split(" ")[1].slice(0, -1) === "Object";
      const isPrimitive = (obj) => {
        switch (typeof obj) {
          case "object": {
            return false;
          }
          default: {
            return true;
          }
        }
      };
      if (isPrimitive(previous) && isPrimitive(current)) {
        if (previous === current) {
          return "";
        }

        return current;
      }

      if (isObject(previous) && isObject(current)) {
        const diff = this.getChanges(
          Object.entries(previous),
          Object.entries(current)
        );

        return diff.reduce((merged, [key, value]) => {
          return {
            ...merged,
            [key]: value,
          };
        }, {});
      }

      const changes = [];

      if (JSON.stringify(previous) === JSON.stringify(current)) {
        return changes;
      }

      for (let i = 0; i < current.length; i++) {
        const item = current[i];

        if (JSON.stringify(item) !== JSON.stringify(previous[i])) {
          changes.push(item);
        }
      }

      return changes;
    },

    async updateCompany() {
      this.onSuccess = false;
      this.isFormSubmitting = true;

      let changedCompanyInfo = this.getChanges(
        this.companyPreviousData,
        this.companyInfoData
      );
      changedCompanyInfo.company_id = this.companyInfoData.company_id;
      const CompanyStatus = await this.$store.dispatch(
        "updateCompany",
        changedCompanyInfo
      );
      if (CompanyStatus.status === "error") {
        console.log("error");
      } else {
        this.onSuccess = true;
        this.$store.commit("setCompanyData", this.companyInfoData);
        this.disableSaveBtn = true;
      }
    },
    acceptAgreement() {
      this.checkbox = true;
      this.agreementDialog = false;
    },

    declineAgreement() {
      this.checkbox = false;
      this.agreementDialog = false;
    },

    changeStep() {
      this.step = this.step == 1 ? 2 : 1;
    },

    async submit() {
      this.showErrorAlert = false;
      let companyDetails = {};
      if (this.selectedCompanySubscription.name !== "Shovel Ready") {
        companyDetails = {
          name: this.companyInfoData.name,
          type: "Professional Excavator",
          address: this.companyInfoData.address,
          phone: this.companyInfoData.phone,
          email: this.companyInfoData.email,
          city: this.companyInfoData.city,
          state: this.companyInfoData.state,
          zip: this.companyInfoData.zip,
          billing_contact: this.companyInfoData.billing_contact,
          billing_address: this.companyInfoData.billing_address,
          billing_phone: this.companyInfoData.billing_phone,
          billing_email: this.companyInfoData.billing_email,
          billing_city: this.companyInfoData.billing_city,
          billing_state: this.companyInfoData.billing_state,
          billing_zip: this.companyInfoData.billing_zip,
          billing_cycle: "Annually",
          eula: this.checkbox,
          isBillAddressSame: this.isBillAddressSame,
        };
      } else {
        this.selectedCompanySubscription.price = 0;
        companyDetails = {
          name: this.companyInfoData.name,
          type: "Professional Excavator",
          address: this.companyInfoData.address,
          phone: this.companyInfoData.phone,
          email: this.companyInfoData.email,
          city: this.companyInfoData.city,
          state: this.companyInfoData.state,
          zip: this.companyInfoData.zip,
          eula: this.checkbox,
        };
      }
      const CompanyStatus = await this.$store.dispatch(
        "createCompany",
        companyDetails
      );
      if (CompanyStatus.status === "error") {
        this.showErrorAlert = true;
      } else {
        this.showErrorAlert = false;
        companyDetails.company_id = CompanyStatus[0].company_id;
        companyDetails.subscription = this.selectedCompanySubscription;
        this.$emit("submitCompanyDetails", companyDetails);
      }
    },
    setBillingAddress() {
      this.isCancelBtnEnabled = true;
      if (this.isCheckedState) {
        this.companyInfoData = {
          ...this.companyInfoData,
          billing_contact: this.companyInfoData.name,
          billing_address: this.companyInfoData.address,
          billing_city: this.companyInfoData.city,
          billing_state: this.companyInfoData.state,
          billing_phone: this.companyInfoData.phone,
          billing_zip: this.companyInfoData.zip,
          billing_email: this.companyInfoData.email,
        };
      } else {
        this.companyInfoData = {
          ...this.companyInfoData,
          billing_contact: "",
          billing_address: "",
          billing_city: "",
          billing_state: "",
          billing_phone: "",
          billing_zip: "",
          billing_email: "",
        };
        this.$v.companyInfoData.billing_contact.$reset();
        this.$v.companyInfoData.billing_address.$reset();
        this.$v.companyInfoData.billing_city.$reset();
        this.$v.companyInfoData.billing_state.$reset();
        this.$v.companyInfoData.billing_phone.$reset();
        this.$v.companyInfoData.billing_zip.$reset();
        this.$v.companyInfoData.billing_email.$reset();
      }
    },
  },
};
</script>

<style lang="scss">
.ml-60 {
  margin-left: 60% !important;
}
.company-info-form-container {
  .billing-type-style {
    border: 1px solid #bdbdbd !important;
  }

  .billing-type-style-primary {
    border: 1px solid #0073d1 !important;
  }

  .billing-address-card {
    border: 1px solid #e0e0e0 !important;
  }

  .v-input--radio-group--row .v-input--radio-group__input {
    flex-wrap: nowrap !important;
  }

  .radio-group-row {
    display: block;
  }
}

.checkbox-bill {
  .v-input--selection-controls__ripple {
    color: #2196f3 !important;
    caret-color: #2196f3 !important;
  }

  .theme--light.v-input--switch .v-input--switch__track {
    color: #2196f3 !important;
    caret-color: #2196f3 !important;
  }

  .theme--light.v-input--switch .v-input--switch__thumb {
    color: #2196f3 !important;
  }
}

.select-view-info {
  .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot {
    padding: 4px;
  }

  .v-select.v-input--dense .v-select__selection--comma {
    margin: 5px 0px 3px 0;
  }

  .v-input__append-inner {
    padding-left: 0px !important;
  }
}
</style>
